// I want to change this component so that the experience card has a heroicons info icon in the bottom right corner that when clicked will initiate a animated flipping of the card to show more details on the reverse side of the card
// The reverse side of the card will have a close icon in the bottom right corner that when clicked will flip the card back to the front side
import React, { useState } from "react";
import { InformationCircleIcon, XMarkIcon, ForwardIcon, BackwardIcon } from "@heroicons/react/24/outline";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { motion } from "framer-motion";

import "react-vertical-timeline-component/style.min.css";

import ReactCardFlip from "react-card-flip";
import { styles } from "../styles";
import { experiences } from "../constants";
import { SectionWrapper } from "../hoc";
import "./CardBack.css";
import "./CardFront.css"; // Add custom CSS here

const ExperienceCard = ({ experience }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <VerticalTimelineElement
            contentStyle={{ background: '#182C5C', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid #232631' }}
            date={experience.date}
            iconStyle={{ background: experience.iconBg }}
            icon={<ExperienceIcon experience={experience} />}
        >
            <ReactCardFlip isFlipped={isFlipped}>

                {/* front */}
                <front>
                    <CardFront experience={experience} handleFlip={handleFlip} />
                </front>
                {/* back */}
                <back>
                    <CardBack experience={experience} handleFlip={handleFlip} />
                </back>
            </ReactCardFlip>
        </VerticalTimelineElement>
    );
};

const ExperienceIcon = ({ experience }) => (
    <div className="flex justify-center items-center w-full h-full">
        {experience.company_link && experience.company_link.trim() !== "" ? (
            <a
                href={experience.company_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                }}
            >
                <img
                    src={experience.icon}
                    alt={experience.company_name}
                    className="w-[60%] h-[60%] object-contain"
                />
            </a>
        ) : (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    cursor: "not-allowed",
                }}
                title="URL not available"
            >
                <img
                    src={experience.icon}
                    alt={experience.company_name}
                    className="w-[60%] h-[60%] object-contain opacity-50"
                />
            </div>
        )}
    </div>
);

const CardFront = ({ experience, handleFlip }) => (
    <div
        className="min-h-[550px] max-h-[550px] pr-0 cursor-pointer overflow-y-scroll scrollbar-hide relative"
        style={{ backgroundColor: "#182C5C" }}
    >
        <h3 className="text-white text-[18px] font-bold">{experience.title}</h3>
        <p
            className="text-secondary-light text-[16px] font-semibold blue-text-gradient"
            style={{ margin: 0 }}
        >
            {experience.company_name}
        </p>

        <ul className="mt-5 list-disc ml-5 space-y-2">
            {experience.points.map((point, index) => (
                <li
                    key={`experience-point-${index}`}
                    className="text-white-100 text-[14px] pl-1 tracking-wider"
                >
                    {point}
                </li>
            ))}
        </ul>

        {/* Forward icon with sparkle effect */}
        {experience.back_enabled && (
            <div
                className="absolute bottom-1 right-1 cursor-pointer group"
                onClick={handleFlip}
            >
        <span className="flex items-center group">
            <span className="text-secondary-light group-hover:sparkle pr-2">My Take</span>
            <ForwardIcon className="w-6 h-6 text-secondary-light group-hover:sparkle" />
        </span>
            </div>
        )}
    </div>
);
// const CardFront = ({ experience, handleFlip }) => (
//     <div className="min-h-[550px] max-h-[550px] pr-0 cursor-pointer overflow-y-scroll scrollbar-hide" style={{backgroundColor: "#182C5C"}}>    {/*<div className="min-h-[200px]">*/}
//         <h3 className="text-white text-[22px] font-bold">{experience.title}</h3>
//         <p className="text-secondary text-[16px] font-semibold blue-text-gradient" style={{ margin: 0 }}>
//             {experience.company_name}
//         </p>
//         <ul className="mt-5 list-disc ml-5 space-y-2">
//             {experience.points.map((point, index) => (
//                 <li key={`experience-point-${index}`} className="text-white-100 text-[14px] pl-1 tracking-wider">
//                     {point}
//                 </li>
//             ))}
//         </ul>
//         <div className="absolute bottom-2 right-2 cursor-pointer" onClick={handleFlip}>
//             <ForwardIcon className="w-6 h-6 text-secondary" />
//         </div>
//     </div>
// );


const CardBack = ({ experience, handleFlip }) => (
    <div
        className="min-h-[550px] max-h-[550px] pr-0 cursor-pointer overflow-y-scroll relative"
        style={{ backgroundColor: "#182C5C", overflow: "scroll" }}
    >
        <h3 className="text-white text-[16px] font-bold">
            My Take on{" "}
            <i style={{ color: experience.company_color }}>
                {experience.company_name.replace(/\s*\(.*\)$/, "")}
            </i>
        </h3>
        <p
            className="text-secondary text-[14px] font-semibold blue-text-gradient"
            style={{ margin: 0 }}
        >
            {experience.back_content.heading}
        </p>

        {/* Star rating display */}
        <div className="flex items-center mt-2 pl-2">
            {[...Array(5)].map((_, index) => {
                const isHalfStar = experience.back_content.num_stars > index && experience.back_content.num_stars < index + 1;
                return (
                <svg
                    key={index}
                    xmlns="http://www.w3.org/2000/svg"
                        fill={index < experience.back_content.num_stars ? (isHalfStar ? "url(#half)" : "yellow") : "gray"}
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                        className={`w-4 h-4 ${experience.back_content.num_stars > 5 ? "glowing-star" : ""}`}
                >
                        <defs>
                            <linearGradient id="half" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="50%" style={{ stopColor: "yellow", stopOpacity: 1 }} />
                                <stop offset="50%" style={{ stopColor: "gray", stopOpacity: 1 }} />
                            </linearGradient>
                        </defs>
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 17.27l5.18 3.73-1.64-5.81L21 9.24l-5.91-.51L12 3 8.91 8.73 3 9.24l4.46 5.95-1.64 5.81L12 17.27z"
                    />
                </svg>
                );
            })}
        </div>

        <ul className="mt-5 list-disc ml-5 space-y-2">
            {experience.back_content.points.map((point, index) => (
                <li
                    key={`experience-point-${index}`}
                    className="text-white-100 text-[14px] pl-1 tracking-wider"
                >
                    {point}
                </li>
            ))}
        </ul>

        {/* Close button with sparkle effect */}
        <div
            className="absolute bottom-1 right-1 cursor-pointer inline-block"
            onClick={handleFlip}
        >
      <span className="flex items-center group">
        <BackwardIcon className="w-6 h-6 text-secondary-light pr-2 group-hover:sparkle" />
        <span className="text-secondary-light group-hover:sparkle">Close</span>
      </span>
        </div>
    </div>
);
// const CardBack = ({ experience, handleFlip }) => (
//     <div className="min-h-[550px] max-h-[550px] pr-0 cursor-pointer overflow-y-scroll scrollbar-hide" style={{backgroundColor: "#182C5C"}}>    {/*<div className="min-h-[200px]">*/}
//
//         <h3 className="text-white text-[16px] font-bold">My Take on <i style={{ color: experience.company_color }}>{experience.company_name}</i></h3>
//         <p className="text-secondary text-[16px] font-semibold blue-text-gradient" style={{margin: 0}}>
//             {experience.back_content.heading}
//         </p>
//
//         {/*i want to add a series of stars here to show the rating of the company - i have a experience.back_contents.stars value that can be used to determine how many stars to highlight - my scale is 1-5, but if the value is above 5 then I want to make the stars allglow*/}
//         {/* Add a series of stars here to show the rating of the company - use experience.back_content.stars value to determine how many stars to highlight - scale is 1-5, if the value is above 5 then make the stars all glow */}
//         <div className="flex items-center mt-2">
//             {[...Array(5)].map((_, index) => (
//                 <svg
//                     key={index}
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill={index < experience.back_content.num_stars ? "yellow" : "gray"}
//                     viewBox="0 0 24 24"
//                     strokeWidth={1.5}
//                     stroke="currentColor"
//                     className="w-4 h-4"
//                 >
//                     <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         d="M12 17.27l5.18 3.73-1.64-5.81L21 9.24l-5.91-.51L12 3 8.91 8.73 3 9.24l4.46 5.95-1.64 5.81L12 17.27z"
//                     />
//                 </svg>
//             ))}
//         </div>
//         <ul className="mt-5 list-disc ml-5 space-y-2">
//             {experience.back_content.points.map((point, index) => (
//                 <li key={`experience-point-${index}`} className="text-white-100 text-[14px] pl-1 tracking-wider">
//                     {point}
//                 </li>
//             ))}
//         </ul>
//         <div className="absolute bottom-1 right-1 cursor-pointer inline-block" onClick={handleFlip}>
//            <span className="flex items-center"><BackwardIcon className="w-6 h-6 text-secondary pr-2" />Close</span>
//             {/*<XMarkIcon className="w-6 h-6 text-secondary"/>*/}
//         </div>
//     </div>
// );
// const ExperienceCard = ({ experience }) => {
//     const [isFlipped, setIsFlipped] = useState(false);

//     const handleFlip = () => {
//         setIsFlipped(!isFlipped);
//     };

//     return (
//         <VerticalTimelineElement
//             contentStyle={{ background: '#182C5C', color: '#fff' }}
//             contentArrowStyle={{ borderRight: '7px solid #232631' }}
//             date={experience.date}
//             iconStyle={{ background: experience.iconBg }}
//             icon={
//                 <div className="flex justify-center items-center w-full h-full">
//                     {experience.company_link && experience.company_link.trim() !== "" ? (
//                         <a
//                             href={experience.company_link}
//                             target="_blank"
//                             rel="noopener noreferrer"
//                             style={{
//                                 display: "flex",
//                                 justifyContent: "center",
//                                 alignItems: "center",
//                                 width: "100%",
//                                 height: "100%",
//                             }}
//                         >
//                             <img
//                                 src={experience.icon}
//                                 alt={experience.company_name}
//                                 className="w-[60%] h-[60%] object-contain"
//                             />
//                         </a>
//                     ) : (
//                         <div
//                             style={{
//                                 display: "flex",
//                                 justifyContent: "center",
//                                 alignItems: "center",
//                                 width: "100%",
//                                 height: "100%",
//                                 cursor: "not-allowed",
//                             }}
//                             title="URL not available"
//                         >
//                             <img
//                                 src={experience.icon}
//                                 alt={experience.company_name}
//                                 className="w-[60%] h-[60%] object-contain opacity-50"
//                             />
//                         </div>
//                     )}
//                 </div>
//             }
//         >
//             <div className={`relative ${isFlipped ? 'flipped' : ''}`}>
//                 <div className="front">
//                     <div>
//                         <h3 className="text-white text-[24px] font-bold">{experience.title}</h3>
//                         <p className="text-secondary text-[16px] font-semibold blue-text-gradient"
//                             style={{ margin: 0 }}>{experience.company_name}</p>
//                     </div>
//                     <ul className="mt-5 list-disc ml-5 space-y-2">
//                         {experience.points.map((point, index) => (
//                             <li
//                                 key={`experience-point-${index}`}
//                                 className="text-white-100 text-[14px] pl-1 tracking-wider"
//                             >
//                                 {point}
//                             </li>
//                         ))}
//                     </ul>
//                     <div className="absolute bottom-2 right-2 cursor-pointer" onClick={handleFlip}>
//                         <InformationCircleIcon className="w-6 h-6 text-secondary" />
//                     </div>
//                 </div>
//                 <div className="back absolute inset-0 bg-[#182C5C] p-4 flex flex-col justify-between">
//                     <div>
//                         <h3 className="text-white text-[24px] font-bold">More Details</h3>
//                         <p className="text-secondary text-[16px] font-semibold blue-text-gradient"
//                             style={{ margin: 0 }}>{experience.company_name}</p>
//                         <p className="text-white mt-4">{experience.details}</p>
//                     </div>
//                     <div className="absolute bottom-2 right-2 cursor-pointer" onClick={handleFlip}>
//                         <XMarkIcon className="w-6 h-6 text-secondary" />
//                     </div>
//                 </div>
//             </div>
//         </VerticalTimelineElement>
//     );
// };
const Experience = () => {
    return (
        <>

            <div
                className="bg-black-100 bg-opacity-50 rounded-[20px] -mt-10"
            >

                <div className={`${styles.padding} bg-tertiary rounded-2xl`}>
                    <motion.div>
                        <p className={`${styles.sectionSubText} opacity-100 jcblue-text`}>
                            My accomplishments
                        </p>
                        <h2 className={`${styles.sectionHeadText} opacity-100`}>
                            Experience
                        </h2>
                        <p className="text-[17px] text-secondary w-full px-14">
                            I am a seasoned technology leader with over 25 years of experience driving innovation in
                            infrastructure, software development, and AI-powered solutions. My expertise spans big data
                            analytics, scalable web services, and systems automation, empowering organizations to
                            achieve transformative results. </p>

                        <p className="text-[17px] text-secondary w-full px-14 mt-5">
                            Throughout my career, I have excelled in senior, architect, and management roles, leading
                            cross-functional teams to deliver large-scale initiatives. I have overseen high-traffic
                            eCommerce platforms, complex search and indexing systems, global data environments, and
                            mission-critical infrastructure. With a passion for leveraging cutting-edge technologies
                            like cloud platforms, AI, and automation, I thrive in creating high-performing, reliable,
                            and impactful systems.

                        </p>
                        <p className="text-[17px] text-secondary w-full px-14 mt-5">

                            In my current role with G-P, I am focused on building and leading teams to develop innovative
                            AI-driven products that solve complex global challenges.
                        </p>
                    </motion.div>
                    <div className='mt-20 flex flex-col'>
                        <VerticalTimeline>
                            {experiences.map((experience, index) => (
                                <ExperienceCard
                                    key={`experience-${index}`}
                                    experience={experience}
                                />
                            ))}
                        </VerticalTimeline>
                    </div>

                </div>

            </div>
        </>
    );
};

export default SectionWrapper(Experience, "experience");
